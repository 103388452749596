import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/users/auth.service";
import AuthUserService from "../services/users/auth.service"
 
export const getUsers = createAsyncThunk(
  "users/get", async (isStaff,thunkAPI) => {
    try {
      const data = await AuthService.getUsers(isStaff);
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteUser(id);
      thunkAPI.dispatch(setMessage(data.message));
      return id;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addUser = createAsyncThunk(

  "users/add", async (data, thunkAPI) => {
    try {
      const response = await AuthService.addUser(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/update", async ({ id, data }, thunkAPI) => {
    try {
      const response = await AuthUserService.updateUserAdmin(id, data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserById = createAsyncThunk(
  "users/getById", async (id, thunkAPI) => {
    try {
      const data = await AuthService.getUserById(id);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, users: null, error: null, };

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [getUsers.fulfilled] : (state, action) => {
      state.isLoader = false;
      state.users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoader = false;
      state.users = null;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.users = state.users.filter(user => user._id !== action.payload);
    },
    [deleteUser.rejected]: (state, action) => {
      state.isLoader = false;
      state.users = null;
    },
    [addUser.rejected]: (state, action) => {
      state.isLoader = false;
      state.error = action.payload;
    },
    [addUser.pending]: (state) => {
      state.isLoader = true;
      state.error = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.users = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.isLoader = false;
      state.users = null;
    },
    [getUserById.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.users = action.payload;
    },
    [getUserById.rejected]: (state, action) => {
      state.isLoader = false;
      state.users = null;
    },
  },
});

const { reducer } = userSlice;
export default reducer;