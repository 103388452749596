import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addNavItems = async (name, isActive) => {
  return await axios
    .post(
      API_URL + "admin/addnavitems",
      {
        name,
        isActive,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addNavInnerItems = async (name, id, slug) => {
  return await axios
    .post(
      API_URL + "admin/addnavinneritems",
      {
        name,
        id,
        slug,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getNavItems = async () => {
  return await instance
    .get(API_URL + "admin/navitems", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateNavItems = async (id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updatenavitems/${id}`,
      { isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateNavItemsData = async (id, name, isActive, slug) => {
  return await axios
    .put(
      API_URL + `admin/updatenavitems/${id}`,
      {
        name,
        isActive,
        slug,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteNavItems = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletenavitems/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const deleteNavInnerItems = async (id, itmId) => {
  return await axios
    .patch(
      API_URL + `admin/deletenavinneritems/${id}`,
      {
        itemId: itmId,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateNavInnerItems = async (id, name, slug) => {
  return await axios
    .put(
      API_URL + `admin/updateinnernavitem/${id}`,
      { name, slug },
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const updateNavItemsOrder = async (newItems) => {
  return await axios
    .post(API_URL + "admin/updatenavitemsorder", { newItems }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const updateNavInnerItemsOrder = async (itemId, innerItems) => {
  return await axios.post(API_URL + `admin/update-navinneritems-order/${itemId}`, { innerItems }, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};


const adsService = {
  addNavItems,
  addNavInnerItems,
  getNavItems,
  updateNavItems,
  updateNavItemsData,
  deleteNavItems,
  deleteNavInnerItems,
  updateNavInnerItems,
  updateNavItemsOrder,
  updateNavInnerItemsOrder
};
export default adsService;
