import axios from "axios";
import { checkEnvType } from "src/helper";
import authHeader from "../auth-header";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

const getTrends = async (page) => {
  return await axios
    .get(API_URL + `users/trends/0`)
    .then((response) => {
      return response.data;
    });
};

const getTrendBySlugOrId = async (slugOrId) => {
  return await axios
    .get(API_URL + `users/trend/${slugOrId}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const TrendsService = { getTrends, getTrendBySlugOrId };
export default TrendsService;