import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import roleService from "../services/roles.service";

export const addRole = createAsyncThunk(
    "roles/add",
    async ({ name, permissions }, thunkAPI) => {
        try {
            const data = await roleService.addRoles(name, permissions);
            thunkAPI.dispatch(setMessage(data.message));
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getRoles = createAsyncThunk(
    "roles/get",
    async (thunkAPI) => {
        try {
            const data = await roleService.getRoles();
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateRole = createAsyncThunk(
    "roles/update",
    async ({ id, name, permissions }, thunkAPI) => {
        try {
            const data = await roleService.updateRoles(id, name, permissions);
            thunkAPI.dispatch(setMessage(data.message));
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteRole = createAsyncThunk(
    "roles/delete",
    async (id, thunkAPI) => {
        try {
            const data = await roleService.deleteRole(id);
            thunkAPI.dispatch(setMessage(data.message));
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getRoleById = createAsyncThunk(
    "roles/getById",
    async (id, thunkAPI) => {
        try {
            const data = await roleService.getRoleById(id);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getPermissions = createAsyncThunk(
    "roles/permissions",
    async (thunkAPI) => {
        try {
            const response = await roleService.getPermissions();
            return response.permissions;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    isLoader: false,
    roles: [],
    role: null,
};

const rolesSlice = createSlice({
    name: "roles",
    initialState,
    extraReducers: {
        [addRole.fulfilled]: (state, action) => {
            state.isLoader = false;
            state.roles = action.payload;
        },
        [addRole.rejected]: (state, action) => {
            state.isLoader = false;
        },
        [getRoles.fulfilled]: (state, action) => {
            state.isLoader = false;
            state.roles = action.payload;
        },
        [getRoles.rejected]: (state, action) => {
            state.isLoader = false;
            state.roles = null;
        },
        [updateRole.fulfilled]: (state, action) => {
            state.isLoader = false;
            state.roles = action.payload;
        },
        [updateRole.rejected]: (state, action) => {
            state.isLoader = false;
        },
        [deleteRole.fulfilled]: (state, action) => {
            state.isLoader = false;
            state.roles = action.payload;
        },
        [deleteRole.rejected]: (state, action) => {
            state.isLoader = false;
        },
        [getPermissions.fulfilled]: (state, action) => {
            state.isLoader = false;
            state.permissions = action.payload;
        },
        [getPermissions.rejected]: (state, action) => {
            state.isLoader = false;
            state.permissions = null;
        },
        [getRoleById.fulfilled]: (state, action) => {
            state.isLoader = false;
            state.role = action.payload;
        },
        [getRoleById.rejected]: (state, action) => {
            state.isLoader = false;
            state.role = null;
        },
    },
});

const { reducer } = rolesSlice;
export default reducer;
