import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/frontpage.service";

export const addFrontPage = createAsyncThunk(
  "frontpage/add", async ({ title, category, description, image, isActive }, thunkAPI) => {

    try {
      const data = await AuthService.addFrontPage(title, category, description, image, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInnerFront = createAsyncThunk(
  "innerfront/add", async ({ title, category, content, innerImage, price, id }, thunkAPI) => {

    try {
      const data = await AuthService.addInnerFront(title, category, content, innerImage, price, id);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFrontPage = createAsyncThunk(
  "frontpage/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getFrontPage();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateFrontPage = createAsyncThunk(
  "frontpage/update", async ({ id, isActive }, thunkAPI) => {

    try {
      const data = await AuthService.updateFrontPage(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateFrontPageData = createAsyncThunk(
  "frontpage/updateInfo", async ({ id, title, category, description, image, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.updateFrontPageData(id, title, category, description, image, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });

  export const updateContent = createAsyncThunk(
    "updatefrontcontent/update", async ({ id, content }, thunkAPI) => {
  
      try {
        const data = await AuthService.updateContent(id, content);
        thunkAPI.dispatch(setMessage(data.message));
        return data.docs;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  );

  export const deleteInnerFront = createAsyncThunk(
    "innerfront/updateInfo", async ({ id, frontId, oldImage }, thunkAPI) => {
      try {
        const data = await AuthService.deleteInnerFront(id, frontId, oldImage);
        thunkAPI.dispatch(setMessage(data.message));
        return data.docs;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    });
      
export const deleteFrontPage = createAsyncThunk(
  "frontpage/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteFrontPage(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }

);

const initialState = { isLoader: false, front: null };

const frontPageSlice = createSlice({
  name: "frontpage",
  initialState,
  extraReducers: {
    [addFrontPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.front = null
    },
    [addFrontPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.front = null
    },
    [getFrontPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.front = action.payload
    },
    [getFrontPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.front = null
    },
    [updateFrontPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.front = action.payload
    },
    [updateFrontPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.front = null
    },
    [updateFrontPageData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.front = action.payload
    },
    [updateFrontPageData.rejected]: (state, action) => {
      state.isLoader = false;
      state.front = null
    },
    [deleteFrontPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.front = action.payload
    },
    [deleteFrontPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.front = null
    }
  },
});

const { reducer } = frontPageSlice;
export default reducer;