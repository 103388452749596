import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/digitalissues.service";

export const getDigital = createAsyncThunk(
  "digital/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getDigital();
      return data.docs;
    } catch (error) {
      console.log(error, "error during user digital info")
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, digital: null };

const digitalSlice = createSlice({
  name: "digitals",
  initialState,
  extraReducers: {
    [getDigital.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.digital = action.payload
    }
  },
});

const { reducer } = digitalSlice;
export default reducer;