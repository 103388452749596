import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/trends.service";
import slugify from 'slugify'; // Add this import

export const addTrends = createAsyncThunk(
  "trends/add",
  async ({ title, category, description, image, isActive, slug }, thunkAPI) => {
    try {
      const data = await AuthService.addTrends(
        title,
        category,
        description,
        image,
        isActive,
        slug
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInnerTrends = createAsyncThunk(
  "innertrends/add",
  async ({ title, category, content, innerImage, price, id }, thunkAPI) => {
    try {
      const data = await AuthService.addInnerTrends(
        title,
        category,
        content,
        innerImage,
        price,
        id
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTrends = createAsyncThunk("trends/get", async (thunkAPI) => {
  try {
    const data = await AuthService.getTrends();
    return data.docs;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const updateTrends = createAsyncThunk(
  "trends/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateTrends(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatecontent/updatetrends",
  async ({ id, content }, thunkAPI) => {
    try {
      const data = await AuthService.updateContent(id, content);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateTrendsData = createAsyncThunk(
  "trends/updateInfo",
  async ({ id, title, category, description, image, oldImage, slug }, thunkAPI) => {
    try {
      const data = await AuthService.updateTrendsData(
        id,
        title,
        category,
        description,
        image,
        oldImage,
        slug || slugify(title, { lower: true, strict: true })
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerTrends = createAsyncThunk(
  "innertrends/updateInfo",
  async ({ id, trendId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerTrends(id, trendId, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteTrends = createAsyncThunk(
  "trends/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteTrends(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, trends: null };

const trendsSlice = createSlice({
  name: "trends",
  initialState,
  extraReducers: {
    [addTrends.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
    [addTrends.rejected]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
    [getTrends.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.trends = action.payload;
    },
    [getTrends.rejected]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
    [updateTrends.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.trends = action.payload;
    },
    [updateTrends.rejected]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
    [updateTrendsData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.trends = action.payload;
    },
    [updateTrendsData.rejected]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
    [deleteTrends.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.trends = action.payload;
    },
    [deleteTrends.rejected]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
  },
});

const { reducer } = trendsSlice;
export default reducer;
