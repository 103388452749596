import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/beauty.service";

export const getBeauty = createAsyncThunk(
  "userbeauty/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getBeauty();
      return data.docs;
    } catch (error) {
      console.log(error, "check the beauty user error");
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, userbeauty: null };

const beautySlice = createSlice({
  name: "userbeauty",
  initialState,
  extraReducers: {
    [getBeauty.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.userbeauty = action.payload
    },
    [getBeauty.rejected]: (state, action) => {
      state.isLoader = false;
      state.userbeauty = null
    }
  },
});

const { reducer } = beautySlice;
export default reducer;