import axios from "axios";
import { checkEnvType } from "src/helper";
import authHeader from "../auth-header";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

const getInnerPages = async () => {
  return await axios
    .get(API_URL + "users/innerpages")
    .then((response) => {
      return response.data;
    });
};

const getInnerPagesExcludeContent = async () => {
  return await axios
    .get(API_URL + "users/innerpages-exclude-content")
    .then((response) => {
      return response.data;
    });
};

const searchInnerPages = async (query) => {
  return await axios
    .get(API_URL + "users/innerpages/search", { params: { query }, headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getInnerPageDetail = async (slugOrId) => {
  return await axios
    .get(API_URL + `users/innerpages/${slugOrId}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const getInnerPageByCategory = async (category) => {
  return await axios
    .get(API_URL + `users/innerpages/category/${category}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

const InnerPageService = { getInnerPages, getInnerPagesExcludeContent, searchInnerPages, getInnerPageDetail, getInnerPageByCategory };
export default InnerPageService;