import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/bottomfooter.service";

export const addBottomFooter = createAsyncThunk(
  "bottom/add", async ({ title, content, category }, thunkAPI) => {

    try {
      const data = await AuthService.addBottomFooter(title,content,category);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBottomFooter = createAsyncThunk(
  "bottom/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getBottomFooter();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateBottomFooter = createAsyncThunk(
  "bottom/updateInfo", async ({ id, updateData }, thunkAPI) => {
    try {
      const data = await AuthService.updateBottomFooter(id, updateData);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });

export const deleteBottomFooter = createAsyncThunk(
  "bottom/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteBottomFooter(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }

);

export const getBottomFooterById = createAsyncThunk(
  "bottom/getById", async (id, thunkAPI) => {
    try {
      const data = await AuthService.getBottomFooterById(id);
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getBottomFooterBySlug = createAsyncThunk(
  "bottom/getBySlug", async (slug, thunkAPI) => {
    try {
      const data = await AuthService.getBottomFooterBySlug(slug);
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const getBottomFooterActive = createAsyncThunk(
  "bottom/getActive", async (thunkAPI) => {
    try {
      const data = await AuthService.getBottomFooterActive();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = { isLoader: false, bottomfoot: null };

const bottomFooterSlice = createSlice({
  name: "bottom",
  initialState,
  extraReducers: {
    [addBottomFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [addBottomFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [getBottomFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [getBottomFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [updateBottomFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [updateBottomFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [updateBottomFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [updateBottomFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [deleteBottomFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [deleteBottomFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [getBottomFooterById.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [getBottomFooterById.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
    [getBottomFooterActive.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [getBottomFooterActive.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = []
    },
    [getBottomFooterBySlug.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = action.payload
    },
    [getBottomFooterBySlug.rejected]: (state, action) => {
      state.isLoader = false;
      state.bottomfoot = null
    },
  },
});

const { reducer } = bottomFooterSlice;
export default reducer;