import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      if (action.payload?.message !== undefined) {
        return { message: action.payload.message, isError: action.payload.isError || false };
      } else {
        return { message: action.payload || '', isError: action.payload?.isError || false };
      }
    },
    clearMessage: (state) => {
      // state.message = "";
      // state.isError = false;
      return {message: "", isError: false}
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
