import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/iframeads.service";

export const getManualAds = createAsyncThunk(
  "iframeads/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getManualAds();
      return data.docs;
    } catch (error) {
      console.log(error, "error during get iframe ads user info")
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, manualadvertise: null };

const adsSlice = createSlice({
  name: "iframeads",
  initialState,
  extraReducers: {
    [getManualAds.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = action.payload
    },
    [getManualAds.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null
    }
  },
});

const { reducer } = adsSlice;
export default reducer;