import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: true,
};

const toogleSlice = createSlice({
  name: "toogle",
  initialState,
  reducers: {
    setToogle: (state, action) => {
      return { toogle: action.payload };
    },
  },
});

const { reducer, actions } = toogleSlice;
export const { setToogle } = actions;
export default reducer;
