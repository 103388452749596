import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/story.service";

export const getStory = createAsyncThunk(
  "userstory/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getStory();
      return data.docs;
    } catch (error) {
      console.log(error, "check the story user error");
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, userstory: null };

const storySlice = createSlice({
  name: "userstory",
  initialState,
  extraReducers: {
    [getStory.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.userstory = action.payload
    },
    [getStory.rejected]: (state, action) => {
      state.isLoader = false;
      state.userstory = null
    }
  },
});

const { reducer } = storySlice;
export default reducer;