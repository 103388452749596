import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import store from '../store'
import { setMessage } from "src/slices/message";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;

  
const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addStory = async (title, url, category, description, image, isActive) => {
  return await axios
    .post(
      API_URL + "admin/addstory",
      {
        title,
        url,
        category,
        description,
        image,
        isActive,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addInnerStory = async (
  title,
  url,
  category,
  content,
  innerImage,
  price,
  id
) => {
  return await axios
    .patch(
      API_URL + `admin/addinnerstory/${id}`,
      {
        title,
        url,
        category,
        content,
        innerImage,
        price,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getStory = async () => {
  return await instance
    .get(API_URL + "admin/story", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateStory = async (id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updatestory/${id}`,
      { isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateContent = async (id, content) => {
  return await axios
    .patch(
      API_URL + `admin/updatestorycontent/${id}`,
      { content },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateStoryData = async (
  id,
  title,
  url,
  category,
  description,
  image,
  oldImage
) => {
  return await axios
    .put(
      API_URL + `admin/updatestory/${id}`,
      {
        title,
        url,
        category,
        description,
        image,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteStory = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletestory/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const deleteInnerStory = async (id, storyId, oldImage) => {
  return await axios
    .patch(
      API_URL + `admin/deleteinnerstory/${id}`,
      {
        storyId,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const storyService = {
  addStory,
  addInnerStory,
  getStory,
  updateStory,
  updateContent,
  updateStoryData,
  deleteStory,
  deleteInnerStory,
};
export default storyService;
