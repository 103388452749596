import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";

import AuthService from "../../services/users/auth.service";
import { getUserAuthToken, setUserEmail } from "src/helper";

export const register = createAsyncThunk(
  "userauth/register",
  async ({ firstName, lastName, email, password, signUpNewsletter }, thunkAPI) => {
    try {
      const response = await AuthService.register(
        firstName,
        lastName,
        email,
        password,
        signUpNewsletter
      );
      const { message } = response;
      setUserEmail(email);
      thunkAPI.dispatch(setMessage(message));
    } catch (error) {
      if (error?.response != undefined) {
        const { message } = error.response.data;
        thunkAPI.dispatch(setMessage({ message }));
        return thunkAPI.rejectWithValue();
      } else {
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const login = createAsyncThunk(
  "userauth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      const { message } = data;
      setUserEmail(email);
      thunkAPI.dispatch(setMessage(message));
      return data;
    } catch (error) {
      console.log(error, "error during user manual sign up");
      if (error?.response != undefined) {
        const { message } = error.response.data;
        thunkAPI.dispatch(setMessage({ message }));
        return thunkAPI.rejectWithValue();
      } else {
        return thunkAPI.rejectWithValue();
      }
    }
  }
);


export const getUser = createAsyncThunk(
  "users/verify",
  async ({email,token}, thunkAPI) => {
    try {
      const toke = getUserAuthToken();
      var data = await AuthService.getUser(email,toke);
      data = data.data
      if (data && data.docs) {
        return data.docs;
      } else {
        return thunkAPI.rejectWithValue('No user data found');
      }
    } catch (error) {
      console.log(error, "error during user manual sign up");
      if (error?.response != undefined) {
        const { message } = error.response.data;
        thunkAPI.dispatch(setMessage({ message }));
        return thunkAPI.rejectWithValue();
      } else {
        return thunkAPI.rejectWithValue();
      }
    }
  }
)

export const updateUser = createAsyncThunk(
  "users/update",
  async ({email,token,data}, thunkAPI) => {
    try {
      var res = await AuthService.updateUser(email,token,data);
      res = res.data
      if (res && res.docs) {
        return res.docs;
      } else {
        return thunkAPI.rejectWithValue('No user data found');
      }
    } catch (error) {
      console.log(error, "error updating user");
      if (error?.response != undefined) {
        const { message } = error.response.data;
        thunkAPI.dispatch(setMessage({ message }));
        return thunkAPI.rejectWithValue();
      } else {
        return thunkAPI.rejectWithValue();
      }
    }
  }
)

const initialState = { isLoggedIn: false, uInfo: null, loading: false, error: null };

const authSlice = createSlice({
  name: "usrauth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.uInfo = action.payload;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.uInfo = null;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.uInfo = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
      state.uInfo = null;
      state.error = action.error.message;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.uInfo = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.uInfo = null;
      state.error = action.error.message;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
