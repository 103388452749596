import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/digitalissue.service";

export const addDigitalIssue = createAsyncThunk(
  "digital/add",
  async ({ title,subtitle, url, image, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.addDigitalIssue(
        title,
        subtitle,
        url,
        image,
        isActive
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDigitalIssue = createAsyncThunk(
  "digital/get",
  async (thunkAPI) => {
    try {
      const data = await AuthService.getDigitalIssue();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDigitalIssue = createAsyncThunk(
  "digital/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateDigitalIssue(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDigitalIssueData = createAsyncThunk(
  "digital/updateInfo",
  async ({ id, title,subtitle, url, image, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.updateDigitalIssueData(
        id,
        title,
        subtitle,
        url,
        image,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteDigitalIssue = createAsyncThunk(
  "digital/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteDigitalIssue(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//inner issues
export const addInnerDigitals = createAsyncThunk(
  "innerdigital/add",
  async ({ title, category, content, innerImage, price, id }, thunkAPI) => {
    try {
      const data = await AuthService.addInnerDigitals(
        title,
        category,
        content,
        innerImage,
        price,
        id
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatecontent/updatedigital",
  async ({ id, content }, thunkAPI) => {
    try {
      const data = await AuthService.updateContent(id, content);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerDigital = createAsyncThunk(
  "innerdigital/updateInfo",
  async ({ id, digitalId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerDigital(
        id,
        digitalId,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, digital: null };

const digitalIssueSlice = createSlice({
  name: "digital",
  initialState,
  extraReducers: {
    [addDigitalIssue.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.digital = null;
    },
    [addDigitalIssue.rejected]: (state, action) => {
      state.isLoader = false;
      state.digital = null;
    },
    [getDigitalIssue.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.digital = action.payload;
    },
    [getDigitalIssue.rejected]: (state, action) => {
      state.isLoader = false;
      state.digital = null;
    },
    [updateDigitalIssue.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.digital = action.payload;
    },
    [updateDigitalIssue.rejected]: (state, action) => {
      state.isLoader = false;
      state.digital = null;
    },
    [updateDigitalIssueData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.digital = action.payload;
    },
    [updateDigitalIssueData.rejected]: (state, action) => {
      state.isLoader = false;
      state.digital = null;
    },
    [deleteDigitalIssue.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.digital = action.payload;
    },
    [deleteDigitalIssue.rejected]: (state, action) => {
      state.isLoader = false;
      state.digital = null;
    },
  },
});

const { reducer } = digitalIssueSlice;
export default reducer;
