import axios from 'axios'
// import authHeader from '../auth-header'

import { checkEnvType } from "src/helper";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

// const getUserBoard = () => {
//   return axios.get(API_URL + 'user', { headers: authHeader() })
// }

const getBanner = async () => {
  return await axios.get(API_URL + "users/banner")
    .then((response) => {
      return response.data;
    });
};

export default { getBanner };
