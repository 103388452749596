import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import bannerReducer from "./slices/banner";
import frontPageReducer from "./slices/frontpage";
import adsReducer from "./slices/ads";
import articalReducer from "./slices/article";
import digitalIssueReducer from "./slices/digitalissues";
import trendsReducer from "./slices/trends";
import messageReducer from "./slices/message";
import toogleReducer from "./slices/toogle";
import girlsProductReducer from "./slices/girlsproducts";
import storyReducer from "./slices/story";
import fashionReducer from "./slices/fashion";
import beautyReducer from "./slices/beauty";
import upperFooterReducer from "./slices/upperfooter";
import bottomFooterReducer from "./slices/bottomfooter";
import votedProductReducer from "./slices/votedproduct";
import manualadsReducer from "./slices/manualads";
import iframeAdsReducer from "./slices/iframeads";
import navItemsReducer from "./slices/navitems";
import innerPagesReducer from "./slices/innerpages";
import userReducer from "./slices/users";
import newsLetterReducerSlice from "./slices/newsletters";
// users
import userBannerReducer from "./slices/users/banner";
import userFrontPageReducer from "./slices/users/frontpage";
import googleLogin from "./slices/users/google";
import digitalReducer from "./slices/users/digitalissues";
import userTrendsReducer from "./slices/users/trends";
import userGirlsProductsReducer from "./slices/users/girlsproducts";
import userBeautyReducer from "./slices/users/beauty";
import userFashionReducer from "./slices/users/fashion";
import userStoryReducer from "./slices/users/story";
import userUpperFooterReducer from "./slices/users/upperfooter";
import userVotedProductReducer from "./slices/users/votedproducts";
import userManualAdsReducer from "./slices/users/manualads";
import userIframeAdsReducer from "./slices/users/iframeads";
import userNavItemsReducer from "./slices/users/navitems";
import userInnerPageReducer from "./slices/users/innerpage";
import usrRegister from "./slices/users/auth";
import rolesReducer from "./slices/roles"
// user dashboard
import userAuthReducer from "./slices/users/auth";
import socialMediaPlatformReducer from "./slices/socialmediaplatform"
const reducer = {
  // admin
  auth: authReducer,
  message: messageReducer,
  sidebarShow: toogleReducer,
  banner: bannerReducer,
  frontPage: frontPageReducer,
  articles: articalReducer,
  trends: trendsReducer,
  digitalIssue: digitalIssueReducer,
  girls: girlsProductReducer,
  stories: storyReducer,
  fashion: fashionReducer,
  beauties: beautyReducer,
  upperFooter: upperFooterReducer,
  bottomFooter: bottomFooterReducer,
  votedProduct: votedProductReducer,
  manualads: manualadsReducer,
  iframeads: iframeAdsReducer,
  navItems: navItemsReducer,
  innerPages: innerPagesReducer,
  users: userReducer,
  newsletter: newsLetterReducerSlice,
  // users
  userBanner: userBannerReducer,
  userFront: userFrontPageReducer,
  ads: adsReducer,
  usrReg: usrRegister,
  glogin: googleLogin,
  digitaluser: digitalReducer,
  userTrends: userTrendsReducer,
  userGirlsProducts: userGirlsProductsReducer,
  userBeauty: userBeautyReducer,
  userFashion: userFashionReducer,
  userStory: userStoryReducer,
  userUpperFooter: userUpperFooterReducer,
  userVotedProduct: userVotedProductReducer,
  userManualads: userManualAdsReducer,
  userIframeads: userIframeAdsReducer,
  userNavItems: userNavItemsReducer,
  userInnerPage: userInnerPageReducer,
  roles: rolesReducer,

  // user dashboard
  userInfo: userAuthReducer,
  socialMediaPlatform: socialMediaPlatformReducer
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
