import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/navitems.service";

export const addNavItems = createAsyncThunk(
  "navitems/add",
  async ({ name, isActive, slug }, thunkAPI) => {
    try {
      const data = await AuthService.addNavItems(name, isActive, slug);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNavInnerItems = createAsyncThunk(
  "navinneritems/add",
  async ({ name, slug, id }, thunkAPI) => {
    try {
      console.log(name, slug, id, "check the data")
      const data = await AuthService.addNavInnerItems(name, id, slug);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getNavItems = createAsyncThunk(
  "navitems/get",
  async (thunkAPI) => {
    try {
      const data = await AuthService.getNavItems();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateNavItems = createAsyncThunk(
  "navitems/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateNavItems(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateNavItemsData = createAsyncThunk(
  "navitems/updateInfo",
  async ({ id, name, isActive, slug }, thunkAPI) => {
    try {
      const data = await AuthService.updateNavItemsData(id, name, isActive, slug);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteNavItems = createAsyncThunk(
  "navitems/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteNavItems(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteNavInnerItems = createAsyncThunk(
  "navinneritems/delete",
  async ({ id, itmId }, thunkAPI) => {
    try {
      const data = await AuthService.deleteNavInnerItems(id, itmId);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateNavInnerItems = createAsyncThunk(
  "navinneritems/update",
  async ({ id, name, slug }, thunkAPI) => {
    try {
      const data = await AuthService.updateNavInnerItems(id, name, slug);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
)
export const updateNavItemsOrder = createAsyncThunk(
  "navitems/updateOrder",
  async (newItems, thunkAPI) => {
    try {
      const data = await AuthService.updateNavItemsOrder(newItems);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
)
export const updateNavInnerItemsOrder = createAsyncThunk(
  "navitems/updateInnerOrder",
  async ({ itemId, innerItems }, thunkAPI) => {
    try {
      const data = await AuthService.updateNavInnerItemsOrder(itemId, innerItems);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, navitems: null };

const navItemSlice = createSlice({
  name: "navitems",
  initialState,
  extraReducers: {
    [addNavItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [addNavItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [addNavInnerItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [addNavInnerItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [getNavItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = action.payload;
    },
    [getNavItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [updateNavItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = action.payload;
    },
    [updateNavItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [updateNavItemsData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = action.payload;
    },
    [updateNavItemsData.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [deleteNavItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = action.payload;
    },
    [deleteNavItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [updateNavItemsOrder.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = action.payload;
    },
    [updateNavItemsOrder.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
    [updateNavInnerItemsOrder.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitems = action.payload;
    },
    [updateNavInnerItemsOrder.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitems = null;
    },
  },
});

const { reducer } = navItemSlice;
export default reducer;
