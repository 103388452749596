import axios from "axios";
import { checkEnvType } from "src/helper";
import authHeader from "./auth-header";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

// const register = (username, email, password) => {
//   return axios.post(API_URL + 'signup', {
//     username,
//     email,
//     password,
//   })
// }

const login = async (username, password) => {
  return await axios
    .post(API_URL + "auth/signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const verifyUser = async (email, token) => {
  return await axios
    .get(API_URL + `userauth/${email}/${token}/activate`, {
      params: { email, token },
    })
    .then((response) => {
      return response.data;
    });
}

const fetchPermissionsByRole = async (role) => {
  return await axios.get(API_URL + `auth/permissions/${role}`);
}
const logout = () => {
  localStorage.removeItem("user");
};


const getMobileStickyAd = async () => {
  return await axios
    .get(API_URL + "users/manualads/mobilestickyad", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}

const getArticlesAd = async () => {
  return await axios
    .get(API_URL + "users/manualads/articles", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}

const authService = {
  login,
  logout,
  verifyUser,
  fetchPermissionsByRole,
  getMobileStickyAd,
  getArticlesAd
};

export default authService;
