import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;

  
const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addTrends = async (title, category, description, image, isActive, slug) => {
  return await axios
    .post(
      API_URL + "admin/addtrends",
      {
        title,
        category,
        description,
        image,
        isActive,
        slug
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addInnerTrends = async (
  title,
  category,
  content,
  innerImage,
  price,
  id
) => {
  return await axios
    .patch(
      API_URL + `admin/addinnertrends/${id}`,
      {
        title,
        category,
        content,
        innerImage,
        price,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getTrends = async () => {
  return await instance
    .get(API_URL + "admin/trends", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateTrends = async (id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updatetrends/${id}`,
      { isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateContent = async (id, content) => {
  return await axios
    .patch(
      API_URL + `admin/updatetrendscontent/${id}`,
      { content },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateTrendsData = async (
  id,
  title,
  category,
  description,
  image,
  oldImage,
  slug
) => {
  return await axios
    .put(
      API_URL + `admin/updatetrends/${id}`,
      {
        title,
        category,
        description,
        image,
        oldImage,
        slug
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteTrends = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletetrends/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const deleteInnerTrends = async (id, trendId, oldImage) => {
  return await axios
    .patch(
      API_URL + `admin/deleteinnertrends/${id}`,
      {
        trendId,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const trendsService = {
  addTrends,
  addInnerTrends,
  getTrends,
  updateTrends,
  updateContent,
  updateTrendsData,
  deleteTrends,
  deleteInnerTrends,
};
export default trendsService;
