import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addDigitalIssue = async (title,subtitle, url, image, isActive) => {
  return await axios
    .post(
      API_URL + "admin/adddigitalissue",
      {
        title,
        subtitle,
        url,
        image,
        isActive,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getDigitalIssue = async () => {
  return await instance
    .get(API_URL + "admin/digitalissue", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateDigitalIssue = async (id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updatedigitalissue/${id}`,
      { isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateDigitalIssueData = async (id, title, subtitle, url, image, oldImage) => {
  return await axios
    .put(
      API_URL + `admin/updatedigitalissue/${id}`,
      {
        title,
        subtitle,
        url,
        image,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteDigitalIssue = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletedigitalissue/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

//inner digitals
const addInnerDigitals = async (
  title,
  category,
  content,
  innerImage,
  price,
  id
) => {
  return await axios
    .patch(
      API_URL + `admin/addinnerdigitals/${id}`,
      {
        title,
        category,
        content,
        innerImage,
        price,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateContent = async (id, content) => {
  return await axios
    .patch(
      API_URL + `admin/updatecontent/${id}`,
      { content },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteInnerDigital = async (id, digitalId, oldImage) => {
  return await axios
    .patch(
      API_URL + `admin/deleteinnerdigitals/${id}`,
      {
        digitalId,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const digitalIssueService = {
  addDigitalIssue,
  getDigitalIssue,
  updateDigitalIssue,
  updateDigitalIssueData,
  deleteDigitalIssue,
  addInnerDigitals,
  updateContent,
  deleteInnerDigital,
};
export default digitalIssueService;
