import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/girlsproducts.service";

export const getGirlsProducts = createAsyncThunk(
  "girlsproduct/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getGirlsProducts();
      return data.docs;
    } catch (error) {
      console.log(error, "error during girls products info")
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, girlsproducts: null };

const girlsProductsSlice = createSlice({
  name: "girlsporducts",
  initialState,
  extraReducers: {
    [getGirlsProducts.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.girlsproducts = action.payload
    }
  },
});

const { reducer } = girlsProductsSlice;
export default reducer;