import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import socialMediaPlatformService from "../services/socialmediaplatform.service";

export const addSocialMediaPlatform = createAsyncThunk(
  "socialMediaPlatform/add",
  async ({ name, url, followerCount, isActive }, thunkAPI) => {
    try {
      const data = await socialMediaPlatformService.addSocialMediaPlatform(
        name,
        url,
        followerCount,
        isActive
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSocialMediaPlatform = createAsyncThunk(
  "socialMediaPlatform/get",
  async (thunkAPI) => {
    try {
      const data = await socialMediaPlatformService.getSocialMediaPlatform();
      return data.docs;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getActiveSocialMediaPlatform = createAsyncThunk(
  "socialMediaPlatform/get/active",
  async (thunkAPI) => {
    try {
      const data = await socialMediaPlatformService.getActiveSocialMediaPlatform();
      return data.docs;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSocialMediaPlatformById = createAsyncThunk(
  "socialMediaPlatform/getById",
  async (id) => {
   try {
     const data = await socialMediaPlatformService.getSocialMediaPlatformById(id)
     return data.docs;
   } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateSocialMediaPlatform = createAsyncThunk(
  "socialMediaPlatform/update",
  async ({ id, updateData }, thunkAPI) => {
    try {
      const data = await socialMediaPlatformService.updateSocialMediaPlatform(id, updateData);
      thunkAPI.dispatch(setMessage(data.message));
      return data.doc;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteSocialMediaPlatform = createAsyncThunk(
  "socialMediaPlatform/delete",
  async (id, thunkAPI) => {
    try {
      const data = await socialMediaPlatformService.deleteSocialMediaPlatform(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSocialMediaPlatformOrder = createAsyncThunk(
  "socialMediaPlatform/updateOrder",
  async (platforms, thunkAPI) => {
    try {
      const response = await socialMediaPlatformService.updateSocialMediaPlatformOrder(platforms);
      thunkAPI.dispatch(setMessage(response.message));
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = { isLoading: false, socialMediaPlatform: null };

const socialMediaPlatformSlice = createSlice({
  name: "socialMediaPlatform",
  initialState,
  extraReducers: {
    [addSocialMediaPlatform.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
    [addSocialMediaPlatform.rejected]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
    [getSocialMediaPlatform.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = action.payload;
    },
    [getSocialMediaPlatform.rejected]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
    [updateSocialMediaPlatform.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = action.payload;
    },
    [updateSocialMediaPlatform.rejected]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
    [deleteSocialMediaPlatform.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = action.payload;
    },
    [deleteSocialMediaPlatform.rejected]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
    [getSocialMediaPlatformById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = action.payload;
    },
    [getSocialMediaPlatformById.rejected]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
    [getActiveSocialMediaPlatform.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = action.payload;
    },
    [getActiveSocialMediaPlatform.rejected]: (state, action) => {
      state.isLoading = false;
      state.socialMediaPlatform = null;
    },
  },
});

const { reducer } = socialMediaPlatformSlice;
export default reducer;
