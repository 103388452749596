import axios from "axios";
import { setUserAuthToken, setUserEmail, setUserLoginType } from "src/helper";
import { checkEnvType } from "src/helper";
import authHeader from "../auth-header";
import { setMessage } from "src/slices/message";
// import store from '../../store'
import axiosInstance from '../../axiosConfig';
const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;


// const instance = axios.create({
//   baseURL: API_URL,
// });
// instance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const message = (error.response && error.response.data && error.response.data.message) ||
//       error.message || error.toString();
//     store.dispatch(setMessage({ message, isError: true }));
//     return Promise.reject(error);
//   }
// );

const register = async (firstName, lastName, email, password, signUpNewsletter) => {
  return await axios
    .post(API_URL + "userauth/signup", {
      firstName,
      lastName,
      email,
      password,
      signUpNewsletter
    })
    .then((response) => {
      return response.data;
    });
};

const addNewsletter = async (email, signUpNewsletter) => {
  return await axios
    .post(API_URL + "userauth/newsletter/save", {
      email,
      signUpNewsletter
    })
    .then((response) => {
      return response.data;
    });
};


const login = async (email, password) => {
  return await axios
    .post(API_URL + "userauth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        setUserAuthToken(response.data.accessToken);
        setUserEmail(email);
        setUserLoginType("manual");
      }

      return response.data;
    });
};
const getUser = async (email, token) => {

  return await axios
    .get(API_URL + "users/verify/getUserInfo", {
      headers: {
        "x-access-token": token,
      },
      params: { email }
    })
    .then((response) => {
      console.log('res', response);
      return response;
    })
    .catch((error) => {
      console.error('Error fetching user info:', error);
    });
};

const getUsers = async (isStaff) => {
  return await axiosInstance
    .get(API_URL + "admin/users", {
      headers: authHeader(),
      params: { isStaff }
    })
    .then((response) => {
      return response.data;
    });
}

const deleteUser = async (id) => {
  return await axios
    .delete(API_URL + `admin/users/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}

const updateUser = async (email, token, data) => {
  return await axios
    .put(API_URL + "users/verify/updateUserInfo", data, {
      headers: {
        "x-access-token": token,
      },
      params: { email }
    })
    .then((response) => {
      console.log('res', response);
      return response;
    })
    .catch((error) => {
      console.error('Error updating user info:', error);
    });

}

const addUser = async (data) => {
  return await axiosInstance
    .post(API_URL + "admin/users/add", data, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}

const updateUserAdmin = async (id,data) => {
  return await axiosInstance
    .put(API_URL + `admin/users/${id}`, data, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}

const getUserById = async (id) => {
  return await axios
    .get(API_URL + `admin/users/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}


const authService = {
  login,
  register,
  getUser,
  updateUser,
  getUsers,
  deleteUser,
  addNewsletter,
  addUser,
  updateUserAdmin,
  getUserById
};

export default authService;
