import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/manualads.service";

export const addAds = createAsyncThunk(
  "manualads/add",
  async ({ image,image_mobile, type, url, location, isActive, is_mobile_sticky_ad }, thunkAPI) => {
    try {
      const data = await AuthService.addAds(
        image,
        image_mobile,
        type,
        url,
        location,
        isActive,
        is_mobile_sticky_ad
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAds = createAsyncThunk("manualads/get", async (thunkAPI) => {
  try {
    const data = await AuthService.getAds();
    return data.docs;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const updateAds = createAsyncThunk(
  "manualads/update",
  async ({ id, location, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateAds(id, location, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateAdsData = createAsyncThunk(
  "manualads/updateInfo",
  async ({ id, image,image_mobile, type, url, location, isActive, oldImage, oldImageMobile, is_mobile_sticky_ad }, thunkAPI) => {
    try {
      const data = await AuthService.updateAdsData(
        id,
        image,
        image_mobile,
        type,
        url,
        location,
        isActive,
        oldImage,
        oldImageMobile,
        is_mobile_sticky_ad,
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteAds = createAsyncThunk(
  "manualads/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteAds(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, manualadvertise: null };

const adsSlice = createSlice({
  name: "manualadvertisement",
  initialState,
  extraReducers: {
    [addAds.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null;
    },
    [addAds.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null;
    },
    [getAds.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = action.payload;
    },
    [getAds.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null;
    },
    [updateAds.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = action.payload;
    },
    [updateAds.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null;
    },
    [updateAdsData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = action.payload;
    },
    [updateAdsData.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null;
    },
    [deleteAds.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = action.payload;
    },
    [deleteAds.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null;
    },
  },
});

const { reducer } = adsSlice;
export default reducer;
