import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/beauty.service";

export const addBeauty = createAsyncThunk(
  "beauty/add",
  async ({ title, url, category, description, image, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.addBeauty(
        title,
        url,
        category,
        description,
        image,
        isActive
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInnerBeauty = createAsyncThunk(
  "innerbeauty/add",
  async (
    { title, url, category, content, innerImage, price, id },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.addInnerBeauty(
        title,
        url,
        category,
        content,
        innerImage,
        price,
        id
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBeauty = createAsyncThunk("beauty/get", async (thunkAPI) => {
  try {
    const data = await AuthService.getBeauty();
    return data.docs;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const updateBeauty = createAsyncThunk(
  "beauty/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateBeauty(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatecontent/updatebeauty",
  async ({ id, content }, thunkAPI) => {
    try {
      const data = await AuthService.updateContent(id, content);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateBeautyData = createAsyncThunk(
  "beauty/updateInfo",
  async (
    { id, title, url, category, description, image, oldImage },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.updateBeautyData(
        id,
        title,
        url,
        category,
        description,
        image,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerBeauty = createAsyncThunk(
  "innerbeauty/updateInfo",
  async ({ id, beautyId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerBeauty(id, beautyId, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteBeauty = createAsyncThunk(
  "beauty/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteBeauty(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, beauty: null };

const beautySlice = createSlice({
  name: "beauty",
  initialState,
  extraReducers: {
    [addBeauty.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.beauty = null;
    },
    [addBeauty.rejected]: (state, action) => {
      state.isLoader = false;
      state.beauty = null;
    },
    [getBeauty.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.beauty = action.payload;
    },
    [getBeauty.rejected]: (state, action) => {
      state.isLoader = false;
      state.beauty = null;
    },
    [updateBeauty.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.beauty = action.payload;
    },
    [updateBeauty.rejected]: (state, action) => {
      state.isLoader = false;
      state.beauty = null;
    },
    [updateBeautyData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.beauty = action.payload;
    },
    [updateBeautyData.rejected]: (state, action) => {
      state.isLoader = false;
      state.beauty = null;
    },
    [deleteBeauty.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.beauty = action.payload;
    },
    [deleteBeauty.rejected]: (state, action) => {
      state.isLoader = false;
      state.beauty = null;
    },
  },
});

const { reducer } = beautySlice;
export default reducer;
