import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/frontpage.service";

export const getFrontPage = createAsyncThunk(
  "userfrontpage/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getFrontPage();
      return data.docs;
    } catch (error) {
      console.log(error, "check the front user error");
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, userfront: null };

const frontPageSlice = createSlice({
  name: "userfrontpage",
  initialState,
  extraReducers: {
    [getFrontPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.userfront = action.payload
    },
    [getFrontPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.userfront = null
    }
  },
});

const { reducer } = frontPageSlice;
export default reducer;