import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TrendsService from "../../services/users/trends.service";

export const getTrendBySlugOrId = createAsyncThunk(
  "trends/getBySlugOrId",
  async (slugOrId, thunkAPI) => {
    try {
      const data = await TrendsService.getTrendBySlugOrId(slugOrId);
      return data.docs;
    } catch (error) {
      console.log(error, "error during get trend by slug or ID");
      return thunkAPI.rejectWithValue();
    }
  }
);

// get all trends
export const getTrends = createAsyncThunk(
  "trends/getAll",
  async (page, thunkAPI) => {
    try {
      const data = await TrendsService.getTrends(page);
      return data.docs;
    } catch (error) {
      console.log(error, "error during get trends");
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  isLoader: false,
  trends: null,
  currentTrend: null,
};

const trendsSlice = createSlice({
  name: "trends",
  initialState,
  extraReducers: {
    // get all trends
    [getTrends.pending]: (state, action) => {
      state.isLoader = true;
    },
    [getTrends.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.trends = action.payload;
    },
    [getTrends.rejected]: (state, action) => {
      state.isLoader = false;
      state.trends = null;
    },
  },
});

const { reducer } = trendsSlice;
export default reducer;