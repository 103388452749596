import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/newsletter.service";

export const addUpperFooter = createAsyncThunk(
  "upper/add", async ({ title, description, image, type, url }, thunkAPI) => {

    try {
      const data = await AuthService.addUpperFooter(title, description, image, type, url);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getNewsletters = createAsyncThunk(
  "upper/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getNewsletters();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUpperFooter = createAsyncThunk(
  "upper/updateInfo", async ({ id, title, description, image, type, url, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.updateUpperFooter(id, title, description, image, type, url, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });

export const deleteNewsletterSubmission = createAsyncThunk(
  "newsletter/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteNewsletterSubmission(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }

);

const initialState = { isLoader: false, upperfoot: null,newsletter:null };

const newsLetterReducerSlice = createSlice({
  name: "upper",
  initialState,
  extraReducers: {
    [addUpperFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = null
    },
    [addUpperFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = null
    },
    [updateUpperFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = action.payload
    },
    [updateUpperFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = null
    },
    [updateUpperFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = action.payload
    },
    [updateUpperFooter.rejected]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = null
    },
    [deleteNewsletterSubmission.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.newsletter = action.payload
    },
    [deleteNewsletterSubmission.rejected]: (state, action) => {
      state.isLoader = false;
      state.newsletter = null
    },
    [getNewsletters.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.newsletter = action.payload
    },
    [getNewsletters.rejected]: (state, action) => {
      state.isLoader = false;
      state.newsletter = null
    },
  },
});

const { reducer } = newsLetterReducerSlice;
export default reducer;