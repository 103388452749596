import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addRoles = async (name,permissions) => {
  return await axios
    .post(API_URL + "admin/roles", {
      name,
      permissions
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getRoles = async () => {
  return await instance
    .get(API_URL + "admin/roles", {
      headers: authHeader()
    })
    .then((response) => {
      console.log("res",response.data)
      return response.data;
    });
};


const updateRoles = async (id, name, permissions) => {
  return await axios
    .put(API_URL + `admin/roles/${id}`, {
      name,
      permissions
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteRole = async (id) => {
  return await axios
    .delete(API_URL + `admin/roles/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getRoleById = async (id) => {
  return await axios
    .get(API_URL + `admin/roles/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getPermissions = async () => {
  return await axios
    .get(API_URL + "admin/permissions", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
}

const roleService = { addRoles, getRoles, updateRoles, deleteRole, getPermissions, getRoleById };
export default roleService;