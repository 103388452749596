import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/navitems.service";

export const getNavItems = createAsyncThunk(
  "usernavitems/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getNavItems();
      return data.docs;
    } catch (error) {
      console.log(error, "error during get nav items user info")
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, navitemsinfo: null };

const adsSlice = createSlice({
  name: "usernavitems",
  initialState,
  extraReducers: {
    [getNavItems.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.navitemsinfo = action.payload
    },
    [getNavItems.rejected]: (state, action) => {
      state.isLoader = false;
      state.navitemsinfo = null
    }
  },
});

const { reducer } = adsSlice;
export default reducer;