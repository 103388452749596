import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setUserAuthToken, setUserLoginType, removeUserInfo } from "src/helper";
import { setMessage } from "../message";

export const googleLogin = createAsyncThunk(
  "google/get",
  async ({ access_token }, thunkAPI) => {
    try {
      setUserAuthToken(access_token);
      setUserLoginType("google");
      thunkAPI.dispatch(setMessage("You have been looged in Successfully!"));
      return true;
    } catch (error) {
      console.log(error, "error during get ads user info");
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeLogin = createAsyncThunk("google/get", async (thunkAPI) => {
  try {
    removeUserInfo();
    return false;
  } catch (error) {
    console.log(error, "error during get ads user info");
    return thunkAPI.rejectWithValue();
  }
});

const initialState = { glogin: false };

const googleSlice = createSlice({
  name: "google",
  initialState,
  extraReducers: {
    [googleLogin.fulfilled]: (state, action) => {
      state.glogin = action.payload;
    },
    [googleLogin.rejected]: (state, action) => {
      state.glogin = false;
    },
    [removeLogin.fulfilled]: (state, action) => {
      state.glogin = false;
    },
    [removeLogin.rejected]: (state, action) => {
      state.glogin = false;
    },
  },
});

const { reducer } = googleSlice;
export default reducer;
