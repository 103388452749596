import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/upperfront.service";

export const getUpperFooter = createAsyncThunk(
  "upper/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getUpperFooter();
      return data.docs;
    } catch (error) {
      console.log(error, "error during user article info")
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, upperfoot: null };

const upperFooterSlice = createSlice({
  name: "upperfooter",
  initialState,
  extraReducers: {
    [getUpperFooter.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.upperfoot = action.payload
    }
  },
});

const { reducer } = upperFooterSlice;
export default reducer;