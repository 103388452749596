import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/fashion.service";

export const getFashion = createAsyncThunk(
  "userfashion/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getFashion();
      return data.docs;
    } catch (error) {
      console.log(error, "check the fasion user error");
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, userfashion: null };

const fasionSlice = createSlice({
  name: "userfashion",
  initialState,
  extraReducers: {
    [getFashion.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.userfashion = action.payload
    },
    [getFashion.rejected]: (state, action) => {
      state.isLoader = false;
      state.userfashion = null
    }
  },
});

const { reducer } = fasionSlice;
export default reducer;