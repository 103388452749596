import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addAds = async (image, image_mobile, type, url, location, isActive, is_mobile_sticky_ad) => {
  return await axios
    .post(
      API_URL + "admin/addmanualads",
      {
        image,
        image_mobile,
        type,
        url,
        location,
        isActive,
        is_mobile_sticky_ad,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getAds = async () => {
  return await instance
    .get(API_URL + "admin/manualadds", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateAds = async (id, location, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updatemanualads/${id}`,
      { location, isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateAdsData = async (id, image, image_mobile, type, url, location, isActive, oldImage,oldImageMobile,is_mobile_sticky_ad) => {
  return await axios
    .put(
      API_URL + `admin/updatemanualads/${id}`,
      {
        image,
        image_mobile,
        type,
        url,
        location,
        isActive,
        oldImage,
        oldImageMobile,
        is_mobile_sticky_ad,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteAds = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletemanualads/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const adsService = { addAds, getAds, updateAds, updateAdsData, deleteAds };
export default adsService;
