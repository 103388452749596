import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";
import AuthServiceFrontend from "../services/users/auth.service"
const user = JSON.parse(localStorage.getItem("user"));
const userPermissions = JSON.parse(localStorage.getItem("permissions")) || [];

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(username, email, password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addNewsletter = createAsyncThunk(
  "auth/newsletter/save",
  async ({ email, signUpNewsletter }, thunkAPI) => {
    try {
      const response = await AuthServiceFrontend.addNewsletter(email, signUpNewsletter);
      const message = response.data?.message || "Subscription successful"; // Access the message safely
      thunkAPI.dispatch(setMessage(message));
       
      return response.data; // Ensure the response data is returned correctly
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      const permissionsResponse = await thunkAPI.dispatch(fetchPermissions(data.roleName));
      localStorage.setItem("permissions", JSON.stringify(permissionsResponse.payload));
      return { user: data, permissions: permissionsResponse.payload };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const verifyUser = createAsyncThunk(
  "auth/verify",
  async ({ email, token }, thunkAPI) => {
    try {
      const data = await AuthService.verifyUser(email, token);
      return data;
    } catch (error) {
      console.error("Error validating user:", error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchPermissions = createAsyncThunk(
  "auth/fetchPermissions",
  async (roles, thunkAPI) => {
    try {
      const response = await AuthService.fetchPermissionsByRole(roles);
      return response.data.permissions;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
  localStorage.removeItem("permissions");
});


const initialState = user
  ? { isLoggedIn: true, user, isSuccess: true, permissions: userPermissions }
  : { isLoggedIn: false, user: null, isSuccess: false, permissions: [] };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.permissions = action.payload.permissions; 
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.permissions = [];
    },
    [addNewsletter.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.isSuccess = true
    },
    [addNewsletter.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.isSuccess = false;
    }
  },
});

const { reducer } = authSlice;
export default reducer;
