import axios from "axios";
import { checkEnvType } from "src/helper";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

const getIframeAds = async () => {
  return await axios
    .get(API_URL + "users/manualads")
    .then((response) => {
      return response.data;
    });
};

const adsService = { getIframeAds };
export default adsService;