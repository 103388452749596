import axios from 'axios';
import { checkEnvType } from './helper';
import { setMessage } from './slices/message'; // Ensure this does not import store or cause a circular dependency

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_PROD_URL;

const axiosInstance = axios.create({
    baseURL: API_URL,
});


export const setupInterceptors = (store) => {
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

            store.dispatch(setMessage({ message, isError: true }));
            return Promise.reject(error);
        }
    );
};

export default axiosInstance;
