import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;



const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addUpperFooter = async (title, description, image, type, url) => {
  return await axios
    .post(API_URL + "admin/addupperfooter", {
      title,
      description,
      image,
      type,
      url,
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getNewsletters = async () => {
  return await instance
    .get(API_URL + "admin/newsletter", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};


const updateUpperFooter = async (id, title, description, image, type, url, oldImage) => {
  return await axios
    .put(API_URL + `admin/updateupperfooter/${id}`, {
      title,
      description,
      image,
      type,
      url,
      oldImage
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteNewsletterSubmission = async (id) => {
  return await axios
    .delete(API_URL + `admin/newsletter/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const newsletterService = { addUpperFooter, getNewsletters, updateUpperFooter, deleteNewsletterSubmission };
export default newsletterService;