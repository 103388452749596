import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/article.service";

export const addArticle = createAsyncThunk(
  "article/add", async ({ title, category, description, image, isActive }, thunkAPI) => {

    try {
      const data = await AuthService.addArticle(title, category, description, image, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInnerArticle = createAsyncThunk(
  "innerarticle/add", async ({ title, category, content, innerImage, price, id }, thunkAPI) => {

    try {
      const data = await AuthService.addInnerArticle(title, category, content, innerImage, price, id);
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getArticle = createAsyncThunk(
  "article/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getArticle();
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateArticle = createAsyncThunk(
  "article/update", async ({ id, isActive }, thunkAPI) => {

    try {
      const data = await AuthService.updateArticle(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatearticlecontent/update", async ({ id, content }, thunkAPI) => {

    try {
      const data = await AuthService.updateContent(id, content);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateArticleData = createAsyncThunk(
  "article/updateInfo", async ({ id, title, category, description, image, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.updateArticleData(id, title, category, description, image, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });

export const deleteArticle = createAsyncThunk(
  "article/delete", async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteArticle(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerArticle = createAsyncThunk(
  "innertrends/updateInfo", async ({ id, articleId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerArticle(id, articleId, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  });


const initialState = { isLoader: false, article: null };

const articleSlice = createSlice({
  name: "articles",
  initialState,
  extraReducers: {
    [addArticle.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.article = null
    },
    [addArticle.rejected]: (state, action) => {
      state.isLoader = false;
      state.article = null
    },
    [getArticle.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.article = action.payload
    },
    [getArticle.rejected]: (state, action) => {
      state.isLoader = false;
      state.article = null
    },
    [updateArticle.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.article = action.payload
    },
    [updateArticle.rejected]: (state, action) => {
      state.isLoader = false;
      state.article = null
    },
    [updateArticleData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.article = action.payload
    },
    [updateArticleData.rejected]: (state, action) => {
      state.isLoader = false;
      state.article = null
    },
    [deleteArticle.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.article = action.payload
    },
    [deleteArticle.rejected]: (state, action) => {
      state.isLoader = false;
      state.article = null
    }
  },
});

const { reducer } = articleSlice;
export default reducer;