import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/banner.service";

export const getBanner = createAsyncThunk(
  "banner/user/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getBanner();
      return data.docs;
    } catch (error) {
      console.log(error, "error during user homepage banner")
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { banner: null };

const bannerSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [getBanner.fulfilled]: (state, action) => {
      state.banner = action.payload
    },
    [getBanner.rejected]: (state, action) => {
      state.banner = null
    },
  },
});

const { reducer } = bannerSlice;
export default reducer;