import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/manualads.service";
import authService from "src/services/auth.service";
export const getManualAds = createAsyncThunk(
  "manualads/get", async (thunkAPI) => {

    try {
      const data = await AuthService.getManualAds();
      return data.docs;
    } catch (error) {
      console.log(error, "error during get manual ads user info")
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getMobileStickyAd = createAsyncThunk(
  "manualads/getMobileAd", async (thunkAPI) => {
    try {
      const data = await authService.getMobileStickyAd();
      return data.docs;
    } catch (error) {
      console.log(error, "error during get mobile sticky ad user info");
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getArticlesAd = createAsyncThunk(
  "manualads/getArticlesAd", async (thunkAPI) => {
    try {
      const data = await authService.getArticlesAd();
      return data.docs;
    } catch (error) {
      console.log(error, "error during get articles ad user info");
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, manualadvertise: null, mobileStickyAd: null, articlesAd: null };

const adsSlice = createSlice({
  name: "manualads",
  initialState,
  extraReducers: {
    [getManualAds.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = action.payload
    },
    [getManualAds.rejected]: (state, action) => {
      state.isLoader = false;
      state.manualadvertise = null
    },
    [getMobileStickyAd.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.mobileStickyAd = action.payload
    },
    [getMobileStickyAd.rejected]: (state, action) => {
      state.isLoader = false;
      state.mobileStickyAd = null
    },
    [getArticlesAd.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.articlesAd = action.payload
    },
    [getArticlesAd.rejected]: (state, action) => {
      state.isLoader = false;
      state.articlesAd = null
    },
  },
});

const { reducer } = adsSlice;
export default reducer;