import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment
  ? process.env.REACT_APP_API_DEV_URL
  : process.env.REACT_APP_API_PROD_URL;

  
const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addGirlsProduct = async (
  title,
  url,
  price,
  description,
  image,
  isActive
) => {
  return await axios
    .post(
      API_URL + "admin/addgirlsproduct",
      {
        title,
        url,
        price,
        description,
        image,
        isActive,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const getGirlsProduct = async () => {
  return await instance
    .get(API_URL + "admin/girlsproduct", {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateGirlsProduct = async (id, isActive) => {
  return await axios
    .patch(
      API_URL + `admin/updategirlsproduct/${id}`,
      { isActive },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateGirlsProductData = async (
  id,
  title,
  url,
  price,
  description,
  image,
  oldImage
) => {
  return await axios
    .put(
      API_URL + `admin/updategirlsproduct/${id}`,
      {
        title,
        url,
        price,
        description,
        image,
        oldImage,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const deleteGirlsProduct = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletegirlsproduct/${id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const girlsProductService = {
  addGirlsProduct,
  getGirlsProduct,
  updateGirlsProduct,
  updateGirlsProductData,
  deleteGirlsProduct,
};
export default girlsProductService;
