import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import store from '../store'
import { setMessage } from "src/slices/message";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);


const addBottomFooter = async (title, content, category) => {
  return await instance
    .post(API_URL + "admin/addbottomfooter", {
      title,
      content,
      category
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getBottomFooter = async () => {
  return await instance
    .get(API_URL + "admin/bottomfooter", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getBottomFooterById = async (id) => {
  return await instance
    .get(API_URL + `admin/bottomfooter/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};


const updateBottomFooter = async (id, updateData) => {
  return await instance
    .put(API_URL + `admin/updatebottomfooter/${id}`, {updateData}, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteBottomFooter = async (id) => {
  return await instance
    .delete(API_URL + `admin/deletebottomfooter/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getBottomFooterActive = async () => {
  return await instance
    .get(API_URL + "userauth/bottomfooters/active")
    .then((response) => {
      return response.data;
    });
}

const getBottomFooterBySlug = async (slug) => {
  return await instance
    .get(API_URL + `userauth/bottomfooters/${slug}`)
    .then((response) => {
      return response.data;
    });
}

const bottomFooterService = { addBottomFooter, getBottomFooter, updateBottomFooter, deleteBottomFooter,getBottomFooterById,getBottomFooterActive,getBottomFooterBySlug  };
export default bottomFooterService;