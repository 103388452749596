import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import axiosInstance from '../axiosConfig';

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;


const addUpperFooter = async (title, description, image, type, url) => {
  return await axiosInstance
    .post(API_URL + "admin/addupperfooter", {
      title,
      description,
      image,
      type,
      url,
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getUpperFooter = async () => {
  return await axiosInstance
    .get(API_URL + "admin/upperfooter", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};


const updateUpperFooter = async (id, title, description, image, type, url, oldImage) => {
  return await axios
    .put(API_URL + `admin/updateupperfooter/${id}`, {
      title,
      description,
      image,
      type,
      url,
      oldImage
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteUpperFooter = async (id) => {
  return await axios
    .delete(API_URL + `admin/deleteupperfooter/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const upperFooterService = { addUpperFooter, getUpperFooter, updateUpperFooter, deleteUpperFooter };
export default upperFooterService;