import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/story.service";

export const addStory = createAsyncThunk(
  "story/add",
  async ({ title, url, category, description, image, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.addStory(
        title,
        url,
        category,
        description,
        image,
        isActive
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInnerStory = createAsyncThunk(
  "innerstory/add",
  async (
    { title, url, category, content, innerImage, price, id },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.addInnerStory(
        title,
        url,
        category,
        content,
        innerImage,
        price,
        id
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStory = createAsyncThunk("story/get", async (thunkAPI) => {
  try {
    const data = await AuthService.getStory();
    return data.docs;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const updateStory = createAsyncThunk(
  "story/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateStory(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatestorycontent/update",
  async ({ id, content }, thunkAPI) => {
    try {
      const data = await AuthService.updateContent(id, content);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateStoryData = createAsyncThunk(
  "story/updateInfo",
  async (
    { id, title, url, category, description, image, oldImage },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.updateStoryData(
        id,
        title,
        url,
        category,
        description,
        image,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerStory = createAsyncThunk(
  "innerstory/updateInfo",
  async ({ id, storyId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerStory(id, storyId, oldImage);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteStory = createAsyncThunk(
  "story/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteStory(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, story: null };

const storySlice = createSlice({
  name: "frontpage",
  initialState,
  extraReducers: {
    [addStory.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.story = null;
    },
    [addStory.rejected]: (state, action) => {
      state.isLoader = false;
      state.story = null;
    },
    [getStory.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.story = action.payload;
    },
    [getStory.rejected]: (state, action) => {
      state.isLoader = false;
      state.story = null;
    },
    [updateStory.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.story = action.payload;
    },
    [updateStory.rejected]: (state, action) => {
      state.isLoader = false;
      state.story = null;
    },
    [updateStoryData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.story = action.payload;
    },
    [updateStoryData.rejected]: (state, action) => {
      state.isLoader = false;
      state.story = null;
    },
    [deleteStory.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.story = action.payload;
    },
    [deleteStory.rejected]: (state, action) => {
      state.isLoader = false;
      state.story = null;
    },
  },
});

const { reducer } = storySlice;
export default reducer;
