import axios from "axios";
import authHeader from "./auth-header";
import { checkEnvType } from "src/helper";
import { setMessage } from "src/slices/message";
import store from '../store'

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;


const instance = axios.create({
  baseURL: API_URL,
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = (error.response && error.response.data && error.response.data.message) ||
      error.message || error.toString();
    store.dispatch(setMessage({ message, isError: true }));
    return Promise.reject(error);
  }
);

const addArticle = async (title, category, description, image, isActive) => {
  return await axios
    .post(API_URL + "admin/addarticle", {
      title,
      category,
      description,
      image,
      isActive,
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const addInnerArticle = async (title, category, content, innerImage, price, id) => {
  return await axios
    .patch(API_URL + `admin/addinnerarticles/${id}`, {
      title, category, content, innerImage, price
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const getArticle = async () => {
  return await instance
    .get(API_URL + "admin/articles", {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};


const updateArticle = async (id, isActive) => {
  return await axios
    .patch(API_URL + `admin/updatearticle/${id}`, { isActive }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const updateContent = async (id, content) => {
  return await axios
    .patch(API_URL + `admin/updatearticlecontent/${id}`, { content }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const updateArticleData = async (id, title, category, description, image, oldImage) => {
  return await axios
    .put(API_URL + `admin/updatearticle/${id}`, {
      title,
      category,
      description,
      image,
      oldImage
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteArticle = async (id) => {
  return await axios
    .delete(API_URL + `admin/deletearticle/${id}`, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const deleteInnerArticle = async (id, articleId, oldImage) => {
  return await axios
    .patch(API_URL + `admin/deleteinnerarticles/${id}`, {
      articleId,
      oldImage
    }, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const frontPageService = { addArticle, getArticle, updateArticle, updateArticleData, deleteArticle, addInnerArticle, updateContent, deleteInnerArticle };
export default frontPageService;