import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/fashion.service";

export const addFashion = createAsyncThunk(
  "fashion/add",
  async ({ title, url, category, description, image, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.addFashion(
        title,
        url,
        category,
        description,
        image,
        isActive
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addInnerFashion = createAsyncThunk(
  "innerfashion/add",
  async (
    { title, url, category, content, innerImage, price, id },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.addInnerFashion(
        title,
        url,
        category,
        content,
        innerImage,
        price,
        id
      );
      thunkAPI.dispatch(setMessage(data.message));
      return true;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFashion = createAsyncThunk("fashion/get", async (thunkAPI) => {
  try {
    const data = await AuthService.getFashion();
    return data.docs;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const updateFashion = createAsyncThunk(
  "fashion/update",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const data = await AuthService.updateFashion(id, isActive);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateContent = createAsyncThunk(
  "updatefashioncontent/update",
  async ({ id, content }, thunkAPI) => {
    try {
      const data = await AuthService.updateContent(id, content);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateFashionData = createAsyncThunk(
  "fashion/updateInfo",
  async (
    { id, title, url, category, description, image, oldImage },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.updateFashionData(
        id,
        title,
        url,
        category,
        description,
        image,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteInnerFashion = createAsyncThunk(
  "innerfashion/updateInfo",
  async ({ id, fashionId, oldImage }, thunkAPI) => {
    try {
      const data = await AuthService.deleteInnerFashion(
        id,
        fashionId,
        oldImage
      );
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteFashion = createAsyncThunk(
  "fashion/delete",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.deleteFashion(id);
      thunkAPI.dispatch(setMessage(data.message));
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, fashion: null };

const fashionSlice = createSlice({
  name: "fashion",
  initialState,
  extraReducers: {
    [addFashion.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.fashion = null;
    },
    [addFashion.rejected]: (state, action) => {
      state.isLoader = false;
      state.fashion = null;
    },
    [getFashion.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.fashion = action.payload;
    },
    [getFashion.rejected]: (state, action) => {
      state.isLoader = false;
      state.fashion = null;
    },
    [updateFashion.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.fashion = action.payload;
    },
    [updateFashion.rejected]: (state, action) => {
      state.isLoader = false;
      state.fashion = null;
    },
    [updateFashionData.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.fashion = action.payload;
    },
    [updateFashionData.rejected]: (state, action) => {
      state.isLoader = false;
      state.fashion = null;
    },
    [deleteFashion.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.fashion = action.payload;
    },
    [deleteFashion.rejected]: (state, action) => {
      state.isLoader = false;
      state.fashion = null;
    },
  },
});

const { reducer } = fashionSlice;
export default reducer;
